import React, { useRef, useState } from 'react'
import { subscribeToNewsletter } from '~api/newsletter'
import Button from '~components/Button/Button'
import Container from '~components/Container/Container'
import Input from '~components/Input/Input'
import Title from '~components/Title/Title'

import s from './Newsletter.scss'

const Newsletter: React.FC = () => {
  const emailRef = useRef<HTMLInputElement>(null)

  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)

  const onSubmit = async (event: React.FormEvent) => {
    setError(null)
    setSuccess(null)

    event.preventDefault()
    event.stopPropagation()

    let email = (emailRef.current && emailRef.current.value) || null

    console.log(email)

    if (!email || email === '') {
      setError('Er is geen emailadres ingevuld.')
      return
    }

    if (emailRef.current && !emailRef.current.validity.valid) {
      setError('Er is geen geldig emailadres ingevuld.')
      return
    }

    try {
      const data = await subscribeToNewsletter(email)

      if (data.message) {
        setSuccess(data.message)
      } else {
        setError('Er is iets fout gegaan, probeer het nogmaals.')
      }
    } catch (e) {
      setError('Er is iets fout gegaan, probeer het nogmaals.')
      console.error(e)
    }
  }

  return (
    <Container>
      <Title title="Houd Mij Op De <span>Hoogte</span>" centered />
      <div className={s.newsletter}>
        <p className={s.newsletter__paragraph}>
          Wil je weten wanneer er een nieuwe podcast online is? Meld je dan nu
          aan met je emailadres.
        </p>
        <form className={s.newsletter__form} onSubmit={onSubmit} noValidate>
          <Input
            type="email"
            placeholder="Emailadres"
            flatten="right"
            inputRef={emailRef}
          />
          <Button onClick={() => {}} flatten="left" color="primary">
            SUBSCRIBE
          </Button>
        </form>
        {error && <p className={s.newsletter__error}>{error}</p>}
        {success && <p className={s.newsletter__success}>{success}</p>}
      </div>
    </Container>
  )
}

export default React.memo(Newsletter)
