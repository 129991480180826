import { ReactNodeLike } from 'prop-types'
import React from 'react'

import classNames from '../../utils/classNames'
import s from './Column.scss'

export type Size = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

type ComponentProps = {
  children: ReactNodeLike
  className?: string | null
  style?: Object
  size?: Size | null
  mobileOnly?: Size | null
  touchOnly?: Size | null
  tabletOnly?: Size | null
  tabletUp?: Size | null
  desktopOnly?: Size | null
  offset?: Size | null
  offsetMobileOnly?: Size | null
  offsetTouchOnly?: Size | null
  offsetTabletOnly?: Size | null
  offsetTabletUp?: Size | null
  offsetDesktopOnly?: Size | null
}

const Column: React.FC<ComponentProps> = ({
  children,
  className = null,
  style = {},
  size = null,
  mobileOnly = null,
  touchOnly = null,
  tabletOnly = null,
  tabletUp = null,
  desktopOnly = null,
  offset = null,
  offsetMobileOnly = null,
  offsetTouchOnly = null,
  offsetTabletOnly = null,
  offsetTabletUp = null,
  offsetDesktopOnly = null
}) => {
  const _className = classNames(s.column, {
    [s[`column--${size}`]]: size,
    [s[`column--${mobileOnly}-mobile-only`]]: mobileOnly,
    [s[`column--${touchOnly}-touch-only`]]: touchOnly,
    [s[`column--${tabletOnly}-tablet-only`]]: tabletOnly,
    [s[`column--${tabletUp}-tablet-up`]]: tabletUp,
    [s[`column--${desktopOnly}-desktop-only`]]: desktopOnly,
    [s[`column--offset-${offset}`]]: offset,
    [s[`column--offset-${offsetMobileOnly}-mobile-only`]]: offsetMobileOnly,
    [s[`column--offset-${offsetTouchOnly}-touch-only`]]: offsetTouchOnly,
    [s[`column--offset-${offsetTabletOnly}-tablet-only`]]: offsetTabletOnly,
    [s[`column--offset-${offsetTabletUp}-tablet-up`]]: offsetTabletUp,
    [s[`column--offset-${offsetDesktopOnly}-desktop-only`]]: offsetDesktopOnly,
    ...(className ? { [className]: true } : {})
  })

  return (
    <div style={style} className={_className}>
      {children}
    </div>
  )
}

export default React.memo(Column)
