import { checkStatus } from '~api'
import { PodcastApi } from '~store/podcast'
import { normalize } from '~utils/normalize'

export const fetchPodcast = async (page: number = 1, limit: number = 10) => {
  const response: PodcastApi = await fetch(
    `${process.env.BASE_URL}/podcast?page=${page}&limit=${limit}&includes=image,audio,season.series.image`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json'
      }
    }
  ).then(res => checkStatus(res, "Can't retrieve podcasts") && res.json())

  return normalize<PodcastApi>(response)
}
