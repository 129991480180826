'use strict'

const hasOwn = {}.hasOwnProperty

export default function classNames(...args: any): string {
  const classes: string[] = []

  for (let arg of args) {
    const argType = typeof arg

    if (argType === 'string' || argType === 'number') {
      classes.push(arg)
    } else if (Array.isArray(arg) && arg.length) {
      const inner = classNames.apply(null, arg)

      if (inner) classes.push(inner)
    } else if (argType === 'object') {
      Object.keys(arg).forEach(key => {
        if (hasOwn.call(arg, key) && arg[key]) {
          classes.push(key)
        }
      })
    }
  }

  return classes.join(' ')
}
