import React from 'react'
import t from '~assets/scss/_trumps.scss'
import Image from '~components/Image/Image'
import { Crops } from '~store/imagePodcast'
import classNames from '~utils/classNames'

import s from './PodcastImage.scss'

type ComponentProps = {
  crops: Crops
}

const PodcastImage: React.FC<ComponentProps> = ({ crops }) => {
  return (
    <div className={s.podcast__image}>
      <Image
        className={s.podcast__image__first}
        crops={crops}
        ratio="1:1"
        width={400}
      />
      <Image
        crops={crops}
        ratio="1:1"
        width={400}
        className={classNames(s.podcast__image__second, t['t--touch-hidden'])}
      />
      <Image
        crops={crops}
        ratio="1:1"
        width={400}
        className={classNames(s.podcast__image__third, t['t--touch-hidden'])}
      />
    </div>
  )
}

export default React.memo(PodcastImage)
