import React from 'react'
import { connect } from 'react-redux'
import Transition from '~components/Transition/Transition'
import { WindowSize } from '~store/settings'
import { State as ReduxState } from '~store/types'
import classNames from '~utils/classNames'

const s = require('./Image.scss')

type State = {
  ready: boolean
  size: string
}

type Props = {
  crops: {
    [key: string]: {
      [key: number]: string
    }
  }
  ratio?: string
  ratioMobile?: string
  ratioTablet?: string
  ratioTouch?: string
  ratioDesktop?: string
  ratioWidescreen?: string
  width?: number
  widthMobile?: number
  widthTablet?: number
  widthTouch?: number
  widthDesktop?: number
  widthWidescreen?: number
  backgroundColor?: string
  className?: string
  transition?: string
  windowSize: WindowSize
}

class ImageTransition extends React.PureComponent<Props, State> {
  static defaultProps = {
    backgroundColor: '#2d2b2c',
    transition: 'fade'
  }

  img: HTMLImageElement | undefined

  constructor(props: Props) {
    super(props)

    this.state = {
      ready: false,
      size: 'desktop'
    }
  }

  get classNames() {
    const { className } = this.props

    return classNames(s.image, {
      ...(className ? { [className]: true } : {})
    })
  }

  get paddingBottom() {
    if (!this.props.ratio) return

    const size = this.props.ratio.split(':').map(item => parseInt(item, 10))
    const percentage = (size[1] / size[0]) * 100

    return `${percentage.toFixed(2)}%`
  }

  get ratio() {
    const props = this.props

    switch (props.windowSize) {
      case 'mobile':
        return props.ratioMobile || props.ratioTouch || props.ratio
      case 'tablet':
        return props.ratioTablet || props.ratioTouch || props.ratio
      case 'desktop':
        return props.ratioDesktop || props.ratio
      case 'widescreen':
        return props.ratioWidescreen || props.ratio
      default:
        return props.ratioDesktop || props.ratio
    }
  }

  get width() {
    const props = this.props

    switch (props.windowSize) {
      case 'mobile':
        return props.widthMobile || props.widthTouch || props.width
      case 'tablet':
        return props.widthTablet || props.widthTouch || props.width
      case 'desktop':
        return props.widthDesktop || props.width
      case 'widescreen':
        return props.widthWidescreen || props.width
      default:
        return props.widthDesktop || props.width
    }
  }

  get image() {
    const { crops } = this.props

    if (!this.ratio || !this.width) {
      return
    }

    return crops[this.ratio][this.width]
  }

  get backgroundImageStyle() {
    return {
      backgroundImage: `url(${this.image})`
    }
  }

  loadImage() {
    this.img = new Image()
    this.img.onload = () => {
      this.setState({ ready: true })
    }

    if (this.image) {
      this.img.src = this.image
    }
  }

  componentDidMount() {
    this.loadImage()
  }

  componentDidUpdate() {
    if (this.img && this.img.src !== this.image) {
      this.setState({ ready: false })
      this.img = undefined
      this.loadImage()
    }
  }

  render() {
    const { backgroundColor, transition } = this.props
    const { ready } = this.state
    const { paddingBottom } = this

    return (
      <div
        className={this.classNames}
        style={{ backgroundColor, paddingBottom }}
      >
        {ready && (
          <Transition name={transition}>
            <div
              className={s.image__background}
              style={this.backgroundImageStyle}
            />
          </Transition>
        )}
      </div>
    )
  }

  componentWillUnmount() {
    if (this.img) {
      this.img.onload = () => {}
      this.img = undefined
    }
  }
}

const mapStateToProps = (state: ReduxState) => ({
  windowSize: state.settings.windowSize
})

export default connect(mapStateToProps)(ImageTransition)
