import { ReactNodeLike } from 'prop-types'
import React from 'react'

import classNames from '../../utils/classNames'
import s from './Row.scss'

type ComponentProps = {
  children: ReactNodeLike
  className?: string
  touchReverseDirection?: boolean
}

const Row: React.FC<ComponentProps> = ({
  children,
  className = undefined,
  touchReverseDirection = false
}) => (
  <div
    className={classNames(className, s.row, {
      [s['row--touchReverse']]: touchReverseDirection
    })}
  >
    {children}
  </div>
)

export default React.memo(Row)
