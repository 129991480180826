import React from 'react'
import Audio from '~components/Audio/Audio'
import { Column, Row } from '~components/Grid'
import PodcastImage from '~components/PodcastImage/PodcastImage'
import { PodcastWithRelations } from '~store/podcast'

import s from './Podcast.scss'

type ComponentProps = {
  podcast: PodcastWithRelations
}

const Podcast: React.FC<ComponentProps> = ({ podcast }) => {
  const episodeNumber = podcast.attributes.episode_number
  const seasonNumber = podcast.season
    ? podcast.season.attributes.season_number
    : '0'
  const changed = new Date(podcast.attributes.changed).toLocaleDateString(
    'nl-NL',
    {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }
  )
  const episode =
    seasonNumber || episodeNumber
      ? `S${seasonNumber.padStart(2, '0')}E${episodeNumber.padStart(2, '0')}`
      : null

  return (
    <div className={s.podcast}>
      <Row>
        {podcast.image && (
          <Column
            size={3}
            mobileOnly={12}
            className={s.podcast__image__container}
          >
            <PodcastImage crops={podcast.image.attributes.crops} />
          </Column>
        )}
        <Column
          size={podcast.image ? 9 : 12}
          mobileOnly={12}
          className={s.podcast__content}
        >
          {podcast.season && (
            <div className={s.podcast__meta}>
              {podcast.season.series && (
                <span>{podcast.season.series.attributes.title}</span>
              )}
              <span>{episode}</span>
              <span>{changed}</span>
            </div>
          )}
          <div className={s.podcast__title}>{podcast.attributes.title}</div>
          <div
            dangerouslySetInnerHTML={{
              __html: podcast.attributes.description
            }}
          ></div>
          {podcast.audio && <Audio src={podcast.audio.attributes.location} />}
        </Column>
      </Row>
    </div>
  )
}

export default React.memo(Podcast)
