import React from 'react'
import classNames from '~/utils/classNames'
import SvgApple from '~assets/svg/apple.svg'
import SvgDeezer from '~assets/svg/deezer.svg'
import SvgSpotify from '~assets/svg/spotify.svg'
import SvgYoutube from '~assets/svg/youtube.svg'
import { MusicSocial } from '~store/types'

const s = require('./Social.scss')

type ComponentProps = {
  social: MusicSocial
}

const Social: React.FC<ComponentProps> = ({ social }) => {
  const socialComponents = {
    apple_music: SvgApple,
    deezer: SvgDeezer,
    spotify: SvgSpotify,
    youtube: SvgYoutube
  }

  const socialNames = {
    apple_music: 'APPLE PODCAST',
    deezer: 'DEEZER',
    spotify: 'SPOTIFY',
    youtube: 'YOUTUBE'
  }

  const SocialSvg = socialComponents[social.type]

  return (
    <div className={classNames(s.social)}>
      <a href={social.url} target="_blank" rel="noopener">
        <SocialSvg /> {socialNames[social.type]}
      </a>
    </div>
  )
}

export default React.memo(Social)
