import '~assets/scss/main.scss'

import * as React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from '~components/Header/Header'
import store from '~store'
import { setWindowSize, WindowSize } from '~store/settings'
import throttle from '~utils/throttle'
import About from '~views/About'
import Error from '~views/Error'
import Home from '~views/Home'
import Podcasts from '~views/Podcasts'

class App extends React.Component {
  retrieveWindowSize() {
    const width =
      window.innerWidth ||
      (document.documentElement && document.documentElement.clientWidth) ||
      document.body.clientWidth

    let size: WindowSize = 'desktop'

    if (width < 800) size = 'mobile'
    else if (width > 800 && width < 1024) size = 'tablet'
    else if (width > 1024 && width < 1600) size = 'desktop'
    else size = 'widescreen'

    store.dispatch(setWindowSize(size))
  }

  componentDidMount() {
    window.addEventListener('resize', throttle(this.retrieveWindowSize, 100))
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div>
            <Header />
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/podcasts" exact>
                <Podcasts />
              </Route>
              <Route path="/about" exact>
                <About />
              </Route>
              <Route path="*">
                <Error />
              </Route>
            </Switch>
          </div>
        </Router>
      </Provider>
    )
  }
}

render(<App />, document.getElementById('root'))
