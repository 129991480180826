import { selectImageSeriesById } from '~store/imageSeries'
import { State } from '~store/types'

import { SeriesWithRelations } from './types'

export const selectSeriesByShortId = (state: State, shortId: number) => {
  const series = Object.values(state.series)
  const serie = series.find(serie => serie.attributes.short_id === shortId)
  if (serie) {
    return selectSeriesById(state, serie.id)
  }

  return undefined
}

export const selectSeriesById = (state: State, id: string) => {
  let series: SeriesWithRelations = Object.assign({}, state.series[id])

  if (series.relationships) {
    const relationships = series.relationships

    if (relationships.image) {
      series.image = selectImageSeriesById(state, relationships.image.data.id)
    }
  }

  return series
}
