import React from 'react'
import AngryBytes from '~assets/svg/angrybytes.svg'

import s from './PoweredBy.scss'

type ComponentProps = {}

const PoweredBy: React.FC<ComponentProps> = () => (
  <div className={s.poweredby}>
    <p className={s.poweredby__text}>Powered by</p>
    <a href="https://angrybytes.com/" target="_blank" rel="noopener">
      <AngryBytes />
    </a>
  </div>
)

export default React.memo(PoweredBy)
