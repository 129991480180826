import { combineReducers, createStore } from 'redux'

import { AudioReducer } from './audio'
import { ImagePodcastReducer } from './imagePodcast'
import { ImageSeriesReducer } from './imageSeries'
import { PagesReducer } from './pages'
import { PodcastReducer } from './podcast'
import { SeasonReducer } from './season'
import { SeriesReducer } from './series'
import { SettingsReducer } from './settings'

export const rootReducer = combineReducers({
  audio: AudioReducer,
  imagePodcast: ImagePodcastReducer,
  imageSeries: ImageSeriesReducer,
  pages: PagesReducer,
  podcast: PodcastReducer,
  season: SeasonReducer,
  series: SeriesReducer,
  settings: SettingsReducer
})

const store = createStore(
  rootReducer,
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
    : undefined
)

export default store
