import { action, ActionType } from 'typesafe-actions'
import { PagesActionTypes, PagesWithPagination } from '~store/pages/types'
import { ApiResponse, ApiState } from '~store/types'

export const fetchPageWithPagination = (
  items: ApiResponse,
  page: keyof PagesWithPagination,
  itemType: keyof ApiState
) => {
  const payload = {
    ...items,
    ids: Object.values(items[itemType] || {}).map(item => item.id),
    pagination: items.meta ? items.meta.pagination : undefined,
    page
  }

  return action(PagesActionTypes.FETCH_PAGE_WITH_PAGINATION, payload)
}

export const refetchPageWithPagination = (
  items: ApiResponse,
  page: keyof PagesWithPagination,
  itemType: keyof ApiState
) => {
  const payload = {
    ...items,
    ids: Object.values(items[itemType] || {}).map(item => item.id),
    page
  }

  return action(PagesActionTypes.REFETCH_PAGE_WITH_PAGINATION, payload)
}

export const updatePageWithPagination = (
  items: ApiResponse,
  page: keyof PagesWithPagination,
  itemType: keyof ApiState
) => {
  const payload = {
    ...items,
    ids: Object.values(items[itemType] || {}).map(item => item.id),
    pagination: items.meta ? items.meta.pagination : undefined,
    page
  }

  return action(PagesActionTypes.UPDATE_PAGE_WITH_PAGINATION, payload)
}

const actions = {
  fetchPageWithPagination,
  refetchPageWithPagination,
  updatePageWithPagination
}

export type PagesAction = ActionType<typeof actions>
