import store from '~/store'
import {
  fetchPageWithPagination,
  PagesWithPagination,
  refetchPageWithPagination
} from '~/store/pages'
import { ApiResponse, ApiState } from '~/store/types'

export const getApiWithPagination = async (
  callApi: Function,
  pageType: keyof PagesWithPagination,
  itemType: keyof ApiState,
  limit: number = 10
) => {
  let updateFilter = false
  const state = store.getState()
  const pageState = state.pages[pageType]

  const hasIds = pageState.ids.length > 0 && !updateFilter

  if (pageState.expire && Date.now() > pageState.expire && hasIds) {
    callApi(1, pageState.ids.length)
      .then((response: ApiResponse) => {
        store.dispatch(refetchPageWithPagination(response, pageType, itemType))
      })
      .catch((err: Error) => {
        console.error(err)
        // store.dispatch(
        //   addDangerNotification('Error retrieving page, please try again')
        // )
      })

    return state[itemType]
  } else if (!hasIds) {
    try {
      const response = await callApi(pageState.current_page, limit)
      store.dispatch(fetchPageWithPagination(response, pageType, itemType))

      return response
    } catch (err) {
      console.error(err)
      throw Error(err)
    }
  }

  return state[itemType]
}
