import { checkStatus } from '~api'

export const subscribeToNewsletter = async (email: string) => {
  const form = new FormData()
  form.append('email', email)

  return await fetch(`${process.env.BASE_URL}/newsletter/subscribe`, {
    method: 'POST',
    mode: 'cors',
    body: form,
    headers: {
      Accept: 'application/json'
    }
  }).then(res => checkStatus(res, "Can't submit newsletter") && res.json())
}
