import './Audio.scss'

import GreenAudioPlayer from 'green-audio-player'
import React, { useEffect, useRef } from 'react'
import classNames from '~utils/classNames'

type ComponentProps = {
  skin?: 'black' | 'white'
  src: string
}

const Audio: React.FC<ComponentProps> = ({ skin = 'black', src }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    new GreenAudioPlayer(ref.current, {
      stopOthersOnPlay: true
    })
  }, [])

  return (
    <div
      ref={ref}
      className={classNames({
        'green-audio-player--black': skin === 'black',
        'green-audio-player--white': skin === 'white'
      })}
    >
      <audio>
        <source src={src} type="audio/mp3" />
      </audio>
    </div>
  )
}

export default React.memo(Audio)
