import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { Dispatch } from 'redux'
import { fetchPodcast } from '~api/podcast'
import image from '~assets/image/curry_en_van_inkel.jpg'
import Button from '~components/Button/Button'
import Container from '~components/Container/Container'
import Featured from '~components/Featured/Featured'
import Footer from '~components/Footer/Footer'
import Hero from '~components/Hero/Hero'
import Podcast from '~components/Podcast/Podcast'
import Spinner from '~components/Spinner/Spinner'
import Title from '~components/Title/Title'
import Transition from '~components/Transition/Transition'
import { updatePageWithPagination } from '~store/pages'
import {
  PodcastApi,
  PodcastWithRelations,
  selectPodcasts
} from '~store/podcast'
import { State } from '~store/types'
import { getApiWithPagination } from '~utils/cache'

type ComponentProps = {
  podcasts: PodcastWithRelations[]
}

const Home: React.FC<ComponentProps> = ({ podcasts }) => {
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  useEffect(() => {
    getApiWithPagination(fetchPodcast, 'index', 'podcast', 4).then(() => {
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <Spinner />
  }

  const featuredPodcast = podcasts.slice(0, 1)
  const otherPodcasts = podcasts.slice(1, 4)

  return (
    <>
      <Transition>
        <div>
          <Hero
            height="large"
            image={image}
            imageAlign="center"
            imageFullscreen
            imageSize="cover"
            opacity={0.1}
          >
            <Featured podcast={featuredPodcast[0]} />
          </Hero>
        </div>
      </Transition>
      <Container>
        <Transition delay={50}>
          <div>
            <Title title="Podcast <span>Afleveringen</span>" />
          </div>
        </Transition>
        {otherPodcasts.map((podcast, idx) => (
          <Transition name="slidein" delay={idx * 75 + 75} key={podcast.id}>
            <div>
              <Podcast podcast={podcast} />
            </div>
          </Transition>
        ))}
      </Container>
      <Container>
        <Transition delay={300}>
          <div>
            <Button onClick={() => history.push('/podcasts')} centered>
              Meer afleveringen
            </Button>

            <Footer />
          </div>
        </Transition>
      </Container>
    </>
  )
}

export default connect(
  (state: State) => ({
    podcasts: selectPodcasts(state, 'index')
  }),
  (dispatch: Dispatch) => ({
    updatePage: (data: PodcastApi) =>
      dispatch(updatePageWithPagination(data, 'index', 'podcast'))
  })
)(Home)
