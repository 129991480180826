import { ReactNodeLike } from 'prop-types'
import React from 'react'
import classNames from '~utils/classNames'

import s from './Container.scss'

type ComponentProps = {
  children: ReactNodeLike
  className?: string
  style?: any
}

const Container: React.FC<ComponentProps> = ({
  children,
  className,
  style
}) => (
  <div className={classNames(s.container, className)} style={style}>
    {children}
  </div>
)

export default React.memo(Container)
