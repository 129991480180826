import { ReactNodeLike } from 'prop-types'
import React from 'react'
import Container from '~components/Container/Container'
import classNames from '~utils/classNames'

import s from './Hero.scss'

type ComponentProps = {
  children?: ReactNodeLike
  height: 'small' | 'medium' | 'large'
  image?: string
  imageAlign?: 'left' | 'center' | 'right'
  imageFullscreen?: boolean
  imageSize?: 'contain' | 'cover'
  label?: string
  opacity?: number
}

const Hero: React.FC<ComponentProps> = ({
  children,
  height,
  image,
  imageAlign,
  imageFullscreen,
  imageSize,
  label,
  opacity
}) => (
  <div
    className={classNames(s.hero, {
      [s['hero--small']]: height === 'small',
      [s['hero--medium']]: height === 'medium',
      [s['hero--large']]: height === 'large'
    })}
  >
    <Container className={s.hero__container}>
      {label && <h1 className={s.hero__label}>{label}</h1>}
      {children}
    </Container>
    <div
      className={classNames(s.hero__image, {
        [s['hero__image--fullscreen']]: imageFullscreen,
        [s['hero__image--left']]: imageAlign === 'left',
        [s['hero__image--center']]: imageAlign === 'center',
        [s['hero__image--right']]: imageAlign === 'right',
        [s['hero__image--contain']]: imageSize === 'contain',
        [s['hero__image--cover']]: imageSize === 'cover'
      })}
      style={{ backgroundImage: image && `url('${image}')`, opacity }}
    ></div>
  </div>
)

export default React.memo(Hero)
