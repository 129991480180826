export type PagesWithPagination = {
  index: PageWithPagination
  podcasts: PageWithPagination
}

export type Pages = PagesWithPagination

export type Page = {
  expire: null | number
  ids: string[]
}

export type PageWithPagination = {
  expire: null | number
  ids: string[]
  current_page: number
  count?: number
  per_page?: number
  total_pages: number
  total?: number
}

export enum PagesActionTypes {
  FETCH_PAGE = '@@pages/FETCH_PAGE',
  FETCH_PAGE_WITH_PAGINATION = '@@pages/FETCH_PAGE_WITH_PAGINATION',
  REFETCH_PAGE_WITH_PAGINATION = '@@pages/REFETCH_PAGE_WITH_PAGINATION',
  UPDATE_PAGE_WITH_PAGINATION = '@@pages/UPDATE_PAGE_WITH_PAGINATION'
}
