import React from 'react'
import { useHistory } from 'react-router'
import Audio from '~components/Audio/Audio'
import { Column, Row } from '~components/Grid'
import PodcastImage from '~components/PodcastImage/PodcastImage'
import Social from '~components/Social/Social'
import { PodcastWithRelations } from '~store/podcast'

import s from './Featured.scss'

type ComponentProps = {
  podcast: PodcastWithRelations
}

const Featured: React.FC<ComponentProps> = ({ podcast }) => {
  const history = useHistory()

  const socials =
    (!!podcast.season &&
      !!podcast.season.series &&
      podcast.season.series.attributes.series_links) ||
    []

  return (
    <>
      <h6 className={s.featured__label}>Laatste aflevering</h6>
      <Row className={s.featured}>
        <Column mobileOnly={12} size={8} className={s.featured__content}>
          <h1 className={s.featured__title}>{podcast.attributes.title}</h1>
          <div
            className={s.featured__description}
            dangerouslySetInnerHTML={{ __html: podcast.attributes.description }}
          ></div>
          {podcast.audio && (
            <Audio src={podcast.audio.attributes.location} skin="white" />
          )}
          <div className={s.featured__socials}>
            {socials.map(social => (
              <Social social={social} key={social.url} />
            ))}
          </div>
        </Column>
        <Column mobileOnly={12} size={4} className={s.featured__image}>
          <div className={s.featured__image__container}>
            {podcast.image && (
              <PodcastImage crops={podcast.image.attributes.crops} />
            )}
          </div>
        </Column>
      </Row>
    </>
  )
}

export default React.memo(Featured)
