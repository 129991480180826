import { SettingsAction } from './actions'
import { Settings, SettingsActionTypes } from './types'

export type State = Settings

const initialState: State = {
  windowSize: 'desktop'
}

const reducer = (state = initialState, action: SettingsAction): State => {
  switch (action.type) {
    case SettingsActionTypes.SET_WINDOWSIZE:
      return { ...state, windowSize: action.payload.windowSize }
    default:
      return state
  }
}

export { reducer as SettingsReducer }
