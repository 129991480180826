import { ReactNodeLike } from 'prop-types'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import classNames from '~utils/classNames'

import s from './NavItem.scss'

type ComponentProps = {
  children: ReactNodeLike
  route: string
  onClick: (open: boolean) => void
} & RouteComponentProps

const NavItem: React.FC<ComponentProps> = ({
  children,
  route,
  location,
  onClick
}) => (
  <div className={s.navitem}>
    <Link
      className={classNames(s.navitem__link, {
        [s['navitem__link--active']]: location.pathname === route
      })}
      to={route}
      onClick={() => onClick(false)}
    >
      {children}
    </Link>
  </div>
)

export default withRouter(NavItem)
