import { selectAudioById } from '~store/audio'
import { selectImagePodcastById } from '~store/imagePodcast'
import { Pages, PageWithPagination } from '~store/pages'
import { selectSeasonById } from '~store/season'
import { KeysOfType, State } from '~store/types'

import { PodcastWithRelations } from './types'

export const selectPodcasts = (
  state: State,
  itemType: KeysOfType<Pages, PageWithPagination>
) => {
  const items: PodcastWithRelations[] = []
  state.pages[itemType].ids.forEach((id: string) => {
    items.push(selectPodcastById(state, id))
  })

  return items
}

export const selectPodcastBySlug = (state: State, slug: string | undefined) => {
  const podcast = Object.values(state.podcast).find(
    podcast => podcast.attributes.slug === slug
  )

  if (podcast) {
    return selectPodcastById(state, podcast.id)
  }
}

export const selectPodcastById = (state: State, id: string) => {
  let podcast: PodcastWithRelations = Object.assign({}, state.podcast[id])

  if (podcast.relationships) {
    const relationships = podcast.relationships
    if (relationships.audio) {
      podcast.audio = selectAudioById(state, relationships.audio.data.id)
    }

    if (relationships.image) {
      podcast.image = selectImagePodcastById(state, relationships.image.data.id)
    }

    if (relationships.season) {
      podcast.season = selectSeasonById(state, relationships.season.data.id)
    }
  }

  return podcast
}
