import { PagesAction } from './actions'
import {
  Pages,
  PagesActionTypes,
  PagesWithPagination,
  PageWithPagination as PageWithPaginationType
} from './types'

const CACHE_TIME = 5 * 60 * 1000

const PageWithPagination: PageWithPaginationType = {
  expire: null,
  ids: [],
  current_page: 1,
  total_pages: 1
}

const initialState: Pages = {
  index: PageWithPagination,
  podcasts: PageWithPagination
}

const reducer = (state = initialState, action: PagesAction): Pages => {
  switch (action.type) {
    // Update pagination, overwrite ids
    case PagesActionTypes.FETCH_PAGE_WITH_PAGINATION: {
      const page = action.payload.page as keyof PagesWithPagination | null
      if (!page) return state

      const pageState = state[page]

      return {
        ...state,
        [page]: {
          ...pageState,
          ...action.payload.pagination,
          ids: action.payload.ids,
          expire: Date.now() + CACHE_TIME
        }
      }
    }
    // Only overwrite ids
    case PagesActionTypes.REFETCH_PAGE_WITH_PAGINATION: {
      const page = action.payload.page as keyof Pages
      const pageState = state[page]

      return {
        ...state,
        [page]: {
          ...pageState,
          ids: action.payload.ids,
          expire: Date.now() + CACHE_TIME
        }
      }
    }
    // Update pagination, add new ids to existing ids array
    case PagesActionTypes.UPDATE_PAGE_WITH_PAGINATION: {
      const page = action.payload.page as keyof Pages
      const pageState = state[page]

      return {
        ...state,
        [page]: {
          ...pageState,
          ...action.payload.pagination,
          ids: [
            ...('ids' in pageState ? pageState.ids : []),
            ...action.payload.ids
          ],
          expire: Date.now() + CACHE_TIME
        }
      }
    }
    default:
      return state
  }
}

export { reducer as PagesReducer }
