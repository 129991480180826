import { ManyRelation, ObjectOf, SingleRelation } from '../store/types'

type RelationshipData = {
  type: string
  id: string
  meta?: {
    [key: string]: any
  }
}

type Relationship = {
  data: RelationshipData[] | RelationshipData
}

type Relationships = {
  [s: string]: Relationship
}

type Attributes = ObjectOf<any>

export type ApiRow = {
  type: string
  id: string
  attributes: { short_id: number } & Attributes
  relationships?: Relationships
}

export type ApiPagination = {
  total: number
  count: number
  per_page: number
  current_page: number
  total_pages: number
}

export type ApiMeta = {
  pagination: ApiPagination
}

export type ApiResponse = {
  data: ApiRow[]
  included?: ApiRow[]
  meta: ApiMeta
}

export type Rel = SingleRelation | ManyRelation

export type Row = {
  id: string
  type: string
  attributes: Attributes
  relationships: { data?: ObjectOf<Rel> }
}

const ucFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

const camelCase = (str: string) =>
  str
    .split('-')
    .map((part: string, index: number) => {
      return index === 0 ? part : ucFirst(part)
    })
    .join('')

export const normalize = <R>(res: any): R => {
  const normalized: any = {}

  const parse = (apiRow: ApiRow): void => {
    const namespace = camelCase(apiRow.type)

    if (!normalized[namespace]) {
      normalized[namespace] = {}
    }

    const relationships: ObjectOf<{ data: Rel }> = {}
    Object.entries(apiRow.relationships || {}).forEach(
      ([type, data]: [string, Relationship]) => {
        let rel: Rel
        if (Array.isArray(data.data)) {
          rel = data.data.map(relation => ({
            ...relation,
            type: camelCase(relation.type)
          }))
        } else {
          rel = {
            ...data.data,
            type: camelCase(data.data.type)
          }
        }

        relationships[type] = { data: rel }
      }
    )

    const { attributes, id } = apiRow

    const row: Row = {
      id,
      type: namespace,
      attributes,
      relationships
    }

    normalized[namespace][apiRow.id] = row
  }

  res.data.forEach(parse)

  if (res.included) {
    res.included.forEach(parse)
  }

  if (res.meta) {
    normalized.meta = res.meta
  }

  return normalized
}
