import { ReactNodeLike } from 'prop-types'
import React from 'react'
import classNames from '~utils/classNames'

import s from './Transition.scss'

type State = {
  start: boolean
  done: boolean
}

type Props = {
  children: ReactNodeLike
  className?: string
  delay: number
  duration: number
  name?: string
}

export default class Transition extends React.PureComponent<Props, State> {
  delayTimeout: undefined | NodeJS.Timeout = undefined
  startTimeout: undefined | NodeJS.Timeout = undefined

  constructor(props: Props) {
    super(props)

    this.state = {
      start: false,
      done: false
    }
  }

  static defaultProps = {
    duration: 300,
    delay: 0,
    name: 'fade'
  }

  get transitionClassNames() {
    const { className, name } = this.props
    const { start, done } = this.state

    return classNames({
      [s[`transition__${name}`]]: start,
      [s[`transition__${name}--start`]]: start && !done,
      ...(className ? { [className]: true } : {})
    })
  }

  delayTransition() {
    const { delay } = this.props

    this.delayTimeout = setTimeout(() => {
      this.setState({ start: true })
      this.startTransition()
    }, delay)
  }

  startTransition() {
    const { duration } = this.props

    this.startTimeout = setTimeout(() => {
      this.setState({ done: true })
    }, duration)
  }

  componentDidMount() {
    this.delayTransition()
  }

  render() {
    const { children } = this.props
    const { start } = this.state

    return React.Children.map(children, (child: any) => {
      if (!start) {
        return
      }

      const className = classNames(
        child.props.className,
        this.transitionClassNames
      )
      return React.cloneElement(child, { className })
    })
  }

  componentWillUnmount() {
    if (this.delayTimeout) {
      clearTimeout(this.delayTimeout)
      this.delayTimeout = undefined
    }

    if (this.startTimeout) {
      clearTimeout(this.startTimeout)
      this.startTimeout = undefined
    }
  }
}
