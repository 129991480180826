import React from 'react'
import { useHistory } from 'react-router'
import Button from '~components/Button/Button'
import Container from '~components/Container/Container'
import Footer from '~components/Footer/Footer'
import Memo from '~components/Memo/Memo'
import Title from '~components/Title/Title'
import Transition from '~components/Transition/Transition'

const Error: React.FC = () => {
  const history = useHistory()

  return (
    <>
      <Transition>
        <div>
          <Container>
            <Title title="Pagina kon niet gevonden worden" centered />
            <Memo />
            <Button centered onClick={() => history.push('/')}>
              Terug naar home
            </Button>
          </Container>
        </div>
      </Transition>
      <Transition delay={300}>
        <div>
          <Footer />
        </div>
      </Transition>
    </>
  )
}

export default React.memo(Error)
