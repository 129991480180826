import React from 'react'
import image from '~assets/image/memo.jpg'

import s from './Memo.scss'

type ComponentProps = {}

const Memo: React.FC<ComponentProps> = ({}) => (
  <div className={s.memo}>
    <img className={s.memo__image} src={image} alt="Memo" />
  </div>
)

export default React.memo(Memo)
