import React from 'react'
import classNames from '~utils/classNames'

import s from './Title.scss'

type ComponentProps = {
  centered?: boolean
  title: string
}

const Title: React.FC<ComponentProps> = ({ centered = false, title }) => (
  <h1
    className={classNames(s.title, {
      [s['title--centered']]: centered
    })}
  >
    <span dangerouslySetInnerHTML={{ __html: title }}></span>
  </h1>
)

export default React.memo(Title)
