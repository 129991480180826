import deepmerge from 'deepmerge'

const overwriteMerge = (_destinationArray: any[], sourceArray: any[]): any[] =>
  sourceArray

const merge = <T>(a: T, b: Partial<T> = {}): T => {
  return deepmerge(a, b, { arrayMerge: overwriteMerge })
}

export default merge
