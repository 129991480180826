import React from 'react'
import classNames from '~utils/classNames'

import s from './Input.scss'

type ComponentProps = {
  flatten?: 'left' | 'right'
  placeholder?: string
  type: string
  inputRef?: React.RefObject<HTMLInputElement>
}

const Input: React.FC<ComponentProps> = ({
  flatten,
  placeholder,
  inputRef,
  type
}) => {
  return (
    <input
      className={classNames(s.input, {
        [s['input--flatten-left']]: flatten === 'left',
        [s['input--flatten-right']]: flatten === 'right'
      })}
      type={type}
      placeholder={placeholder}
      ref={inputRef}
    />
  )
}

export default React.memo(Input)
