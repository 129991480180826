import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { State } from '~/store/types'
import { fetchPodcast } from '~api/podcast'
import image from '~assets/image/podcast.jpg'
import Button from '~components/Button/Button'
import Container from '~components/Container/Container'
import Footer from '~components/Footer/Footer'
import Hero from '~components/Hero/Hero'
import Podcast from '~components/Podcast/Podcast'
import Spinner from '~components/Spinner/Spinner'
import Transition from '~components/Transition/Transition'
import { updatePageWithPagination } from '~store/pages'
import {
  PodcastApi,
  PodcastWithRelations,
  selectPodcasts
} from '~store/podcast'
import { getApiWithPagination } from '~utils/cache'

type ComponentProps = {
  currentPage: number
  totalPages: number
  podcasts: PodcastWithRelations[]
  updatePage: (data: PodcastApi) => void
}

const TRANSITION_DELAY = 75
const PODCAST_LIMIT = 5

const Podcasts: React.FC<ComponentProps> = ({
  currentPage,
  totalPages,
  podcasts,
  updatePage
}) => {
  const [loading, setLoading] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [loadMore, setLoadMore] = useState(false)

  useEffect(() => {
    getApiWithPagination(
      fetchPodcast,
      'podcasts',
      'podcast',
      PODCAST_LIMIT
    ).then(() => {
      setLoading(false)
    })
  }, [])

  const nextPage = async () => {
    if (currentPage < totalPages) {
      try {
        setButtonLoading(true)

        const response = await fetchPodcast(currentPage + 1, PODCAST_LIMIT)

        updatePage(response)
      } catch (e) {
        console.error(e)
      } finally {
        setButtonLoading(false)
        setLoadMore(true)
      }
    }
  }

  const calculateDelay = (index: number) => {
    if (loadMore) {
      return TRANSITION_DELAY * (index % PODCAST_LIMIT)
    }

    return Math.min(index * TRANSITION_DELAY, 1000)
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <>
      <Transition>
        <div>
          <Hero
            height="small"
            label="Afleveringen"
            image={image}
            imageAlign="center"
            imageSize="cover"
          />
        </div>
      </Transition>
      <Container>
        {podcasts.map((podcast, idx) => (
          <Transition
            name="slidein"
            delay={calculateDelay(idx)}
            key={podcast.id}
          >
            <div>
              <Podcast podcast={podcast} />
            </div>
          </Transition>
        ))}
      </Container>
      <Container>
        {currentPage < totalPages && (
          <Transition delay={500}>
            <div>
              <Button onClick={nextPage} centered loading={buttonLoading}>
                Meer afleveringen
              </Button>
            </div>
          </Transition>
        )}
        <Transition delay={500}>
          <div>
            <Footer />
          </div>
        </Transition>
      </Container>
    </>
  )
}

export default connect(
  (state: State) => ({
    currentPage: state.pages.podcasts.current_page,
    totalPages: state.pages.podcasts.total_pages,
    podcasts: selectPodcasts(state, 'podcasts')
  }),
  (dispatch: Dispatch) => ({
    updatePage: (data: PodcastApi) =>
      dispatch(updatePageWithPagination(data, 'podcasts', 'podcast'))
  })
)(Podcasts)
