import React from 'react'

import s from './Spinner.scss'

type ComponentProps = {}

const Spinner: React.FC<ComponentProps> = ({}) => (
  <div className={s.spinner}></div>
)

export default React.memo(Spinner)
