import React from 'react'
import Newsletter from '~components/Newsletter/Newsletter'
import PoweredBy from '~components/PoweredBy/PoweredBy'

import s from './Footer.scss'

type ComponentProps = {}

const Footer: React.FC<ComponentProps> = ({}) => (
  <footer className={s.footer}>
    <Newsletter />
    <PoweredBy />
  </footer>
)

export default React.memo(Footer)
