export type Settings = {
  windowSize: WindowSize
}

export type WindowSize = 'mobile' | 'tablet' | 'desktop' | 'widescreen'

export type Social = {
  type: 'applemusic' | 'spotify'
  url: string
}

export enum SettingsActionTypes {
  SET_WINDOWSIZE = '@@settings/SET_WINDOWSIZE'
}
