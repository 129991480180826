import React from 'react'
import image from '~assets/image/studio.jpg'
import Container from '~components/Container/Container'
import Footer from '~components/Footer/Footer'
import Hero from '~components/Hero/Hero'
import Transition from '~components/Transition/Transition'

type ComponentProps = {}

const About: React.FC<ComponentProps> = ({}) => (
  <>
    <Transition>
      <div>
        <Hero
          height="large"
          label="Over Curry en Van Inkel"
          image={image}
          imageSize="cover"
          imageFullscreen
        />
      </div>
    </Transition>
    <Container>
      <Transition name="slidein" delay={75}>
        <div>
          <p>
            De podcast van het legendarische radioprogramma dat van 12 oktober
            1984 tot 16 oktober 1987 werd uitgezonden door Hit Radio Veronica op
            vrijdagavond tussen 19:00 en 22;00 uur. Het stijve radiobolwerk in
            Hilversum werd stevig wakker geschud door twee jonge radiomakers van
            destijds 22 en 18 jaar. Danig geïnspireerd door Amerikaanse DJ’s
            brachten zij een eerlijke, gewaagde, brutale vorm van personality
            radio waar jong Nederland massaal voor viel. Het betekende ook de
            introductie van een stijl van duo presentatie die nu 35 jaar later
            nog steeds in dezelfde vorm op de hedendaagse radio te horen is in
            programma’s als Coen &amp; Sander en in het verleden Extra Weekend
            met Michiel Veenstra en Gerard Ekdom.
          </p>

          <p>
            Aan de hand van oude audio fragmenten (uit Jeroen’s gedigitaliseerde
            privé archief) praten we met personen uit de Curry en van Inkel tijd
            die op de één of andere manier met het programma te maken hebben
            gehad. Je hoort de verhalen van achter de schermen, niet eerder
            gehoord audio materiaal en zogenaamde air-checks. (programma
            fragmenten)
          </p>
        </div>
      </Transition>
    </Container>
    <Transition delay={500}>
      <div>
        <Footer />
      </div>
    </Transition>
  </>
)

export default React.memo(About)
