import { PagesAction, PagesActionTypes } from '~store/pages'
import { ObjectOf } from '~store/types'
import deepmerge from '~utils/deepmerge'

import { Audio } from './types'

export type State = ObjectOf<Audio>

const initialState: State = {}

const reducer = (state = initialState, action: PagesAction): State => {
  switch (action.type) {
    case PagesActionTypes.FETCH_PAGE:
    case PagesActionTypes.UPDATE_PAGE_WITH_PAGINATION:
    case PagesActionTypes.FETCH_PAGE_WITH_PAGINATION:
    case PagesActionTypes.REFETCH_PAGE_WITH_PAGINATION:
      return deepmerge<State>(state, action.payload.audio)
    default:
      return state
  }
}

export { reducer as AudioReducer }
