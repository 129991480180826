import { action, ActionType } from 'typesafe-actions'

import { SettingsActionTypes, WindowSize } from './types'

export const setWindowSize = (windowSize: WindowSize) => {
  return action(SettingsActionTypes.SET_WINDOWSIZE, { windowSize })
}

const actions = {
  setWindowSize
}

export type SettingsAction = ActionType<typeof actions>
