import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import t from '~assets/scss/_trumps.scss'
import Close from '~assets/svg/close.svg'
import CurryEnVanInkel from '~assets/svg/curryenvaninkel.svg'
import Hamburger from '~assets/svg/hamburger.svg'
import Container from '~components/Container/Container'
import { Column, Row } from '~components/Grid'
import NavItem from '~components/NavItem/NavItem'
import classNames from '~utils/classNames'

import s from './Header.scss'

type ComponentProps = {}

const Header: React.FC<ComponentProps> = ({}) => {
  const [open, setOpen] = useState(false)

  return (
    <Container>
      <Row>
        <Column tabletUp={4} mobileOnly={10} className={s.header__logo}>
          <Link to="/">
            <CurryEnVanInkel />
          </Link>
        </Column>
        <Column
          tabletUp={8}
          mobileOnly={12}
          className={classNames(s.header__nav, {
            [s['header__nav--open']]: open
          })}
        >
          <NavItem route="/" onClick={setOpen}>
            Home
          </NavItem>
          <NavItem route="/podcasts" onClick={setOpen}>
            Afleveringen
          </NavItem>
          <NavItem route="/about" onClick={setOpen}>
            Over
          </NavItem>
          <div
            onClick={() => setOpen(false)}
            className={classNames(s.header__close, t['t--tablet-up-hidden'])}
          >
            <Close />
          </div>
        </Column>
        <Column
          mobileOnly={2}
          className={classNames(s.header__hamburger, t['t--tablet-up-hidden'])}
        >
          <Hamburger onClick={() => setOpen(true)} />
        </Column>
      </Row>
    </Container>
  )
}

export default React.memo(Header)
