import { selectSeriesById } from '~store/series'
import { State } from '~store/types'

import { SeasonWithRelations } from './types'

export const selectSeasonById = (state: State, id: string) => {
  let season: SeasonWithRelations = Object.assign({}, state.season[id])

  if (season.relationships) {
    const relationships = season.relationships

    if (relationships.series) {
      season.series = selectSeriesById(state, relationships.series.data.id)
    }
  }

  return season
}
