import { ReactNodeLike } from 'prop-types'
import React from 'react'
import classNames from '~utils/classNames'

import s from './Button.scss'

type ComponentProps = {
  centered?: boolean
  children: ReactNodeLike
  color?: 'primary' | 'black'
  disabled?: boolean
  flatten?: 'left' | 'right'
  loading?: boolean
  onClick: () => void
}

const Button: React.FC<ComponentProps> = ({
  centered = false,
  children,
  color = 'black',
  disabled = false,
  flatten,
  loading = false,
  onClick
}) => (
  <div
    className={classNames(s.button__container, {
      [s['button__container--centered']]: centered
    })}
  >
    <button
      className={classNames(s.button, s[`button--${color}`], {
        [s['button--disabled']]: disabled,
        [s['button--loading']]: loading,
        [s['button--flatten-left']]: flatten === 'left',
        [s['button--flatten-right']]: flatten === 'right'
      })}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {children}
    </button>
  </div>
)

export default React.memo(Button)
